import { render, staticRenderFns } from "./DroneChangePolicyModal.vue?vue&type=template&id=4ec700cc"
import script from "./DroneChangePolicyModal.vue?vue&type=script&lang=js"
export * from "./DroneChangePolicyModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@i18n/drone/drone.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneChangePolicyModal.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./DroneChangePolicyModal.vue?vue&type=custom&index=1&blockType=i18n&locale=th&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports