<i18n locale="th" lang="yaml" >
enum.drone.search.input.sn.placeholder : "ระบุ S/N ของ Drone"
enum.drone.search.input.ownername.placeholder : "ระบุชื่อเจ้าของ Drone"
enum.drone.search.input.email.placeholder : "ระบุ Email เจ้าของ Drone"
enum.drone.search.input.phone.placeholder : "ระบุเบอร์มือถือเจ้าของ Drone"

drone.search.select.description: "ค้นหาโดย"
drone.search.input.sn.label : "S/N"
drone.search.input.ownername.label : "ชื่อเจ้าของโดรน"
drone.search.input.email.label : "Email"
drone.search.input.phone.label : "เบอร์โทรศัพท์"
</i18n>

<template>
	<a-form layout="inline">
		<label class="blue-text">{{$t('drone.search.select.description')}}</label>
		<a-select class="dropdown-list" :default-value="filter.search_type" @change="handleChangeField">
			<a-select-option value="sn"> {{$t('drone.search.input.sn.label')}} </a-select-option>
			<a-select-option value="ownername"> {{$t('drone.search.input.ownername.label')}} </a-select-option>
			<a-select-option value="email"> {{$t('drone.search.input.email.label')}} </a-select-option>
			<a-select-option value="phone"> {{$t('drone.search.input.phone.label')}} </a-select-option>
		</a-select>
		<span class="blue-text">:</span>

		<a-input-search
			v-model="filter.keyword"
			class="input-textbox"
			:placeholder="$tenum('drone.search.input', `${filter.search_type}.placeholder`)"
			allow-clear
			@search="handleSearch">
			<a-button slot="enterButton" icon="search" type="primary" :disabled="!$notEmpty(filter.keyword)"/>
		</a-input-search>
	</a-form>
</template>

<script>
export default {
	props: {
		serialNo: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			filter: {
				keyword: this.serialNo,
				search_type: 'sn',
			},
		}
	},
	methods: {
		handleChangeField(searchType) {
			this.filter.search_type = searchType;
			this.filter.keyword = '';
		},
		handleSearch() {
			if (this.$notEmpty(this.filter.keyword)) {
				const filterObj = JSON.parse(JSON.stringify(this.filter));
				this.$emit('submit-native-search', filterObj);
			}
		},
		getSearchFilter() {
			return JSON.parse(JSON.stringify(this.filter));
		},
	},
}
</script>

<style lang="less" scoped>
	label {
		line-height: 32px;
		vertical-align: middle;
		margin-right: 2px;
		color: @info-color;
		white-space: nowrap;
	}
	.dropdown-list {
		width: 130px;
	}
	.input-textbox {
		width: 250px;
	}
	.blue-text {
		color: #1890ff;
	}
</style>
