var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.$t('drone_update_modal.title'),"ok-text":_vm.$t('common.save'),"width":400,"confirm-loading":_vm.loading,"cancel-button-props":{ props: { disabled: _vm.loading } },"mask-closable":!_vm.loading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.formObj}},[_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.firmware'),"extra":_vm.$t('drone.field.firmware.help')}},[_c('FirmwareSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'firmware_id' ,
					{
						rules: [
							{ required: true,message: _vm.$tt('validate.required','drone.field.firmware') },
						],
					},
				]),expression:"[\n\t\t\t\t\t'firmware_id' ,\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone.field.firmware') },\n\t\t\t\t\t\t],\n\t\t\t\t\t},\n\t\t\t\t]"}],attrs:{"firmware-set-id":_vm.droneModel.firmware_set_id}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.latest_flight_time_sec'),"extra":_vm.$t('drone.field.latest_flight_time_sec.help')}},[_c('FlightTimeInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'latest_flight_time_sec' ,
					{
						rules: [
						{ required: true ,message: _vm.$tt('validate.required','drone.field.latest_flight_time_sec') },
					] },
				]),expression:"[\n\t\t\t\t\t'latest_flight_time_sec' ,\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },\n\t\t\t\t\t] },\n\t\t\t\t]"}]})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('update.field.updated_date'),"help":_vm.$t('update.field.updated_date.help')}},[_c('MyDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['updated_date',
					{
						rules: [
							{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','update.field.updated_date') },
						] ,
					}]),expression:"['updated_date',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','update.field.updated_date') },\n\t\t\t\t\t\t] ,\n\t\t\t\t\t}]"}],attrs:{"format":"DD MMMM YYYY"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('update.field.updated_time'),"help":_vm.$t('update.field.updated_time.help')}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['updated_time',
					{
						rules: [
							{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','update.field.updated_time') },
						] ,
					}]),expression:"['updated_time',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','update.field.updated_time') },\n\t\t\t\t\t\t] ,\n\t\t\t\t\t}]"}],attrs:{"input-read-only":"","format":"HH:mm","value-format":"HH:mm"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }