<i18n locale="th" lang="yaml" >
page.title : "{name} :: ข้อมูล Drone"
page.title.search : "ค้นหา Drone"
search.input.label : "S/N"
search.input.placeholder : "ระบุ S/N ของ Drone"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<div v-if="!$route.meta.hideSearch && !$route.query.from_create" class="input-pane">
			<DroneSearchPanel :key="searchPanelKey" ref="searchFilterRef" :serial-no="paramSerialNoValue" @submit-native-search="handleNativeSearch" />
		</div>

		<DroneSearchError
			v-if="$notEmpty(searchError)"
			:serial-no="paramSerialNoValue"
			:error="searchError"
			class="error-pane"/>
		<div v-if="isShowDroneTable">
			<a-card :bordered="false">
				<DroneTable 
					bordered
					:show-pixhawk="true"
					:row-key="record => record.id"
					:data-source="droneDataList"
					:pagination="pagination"
					:loading="loading"
					:hide-register="true"
					:hide-status="true"
					:hide-firmware="true"
					:hide-phone="false"
					:hide-email="false"
					@change="handleTableChangePage"/>
			</a-card>
		</div>
		<div v-else>
			<div v-if="isValid" class="mylayout-left-right responsive">
				<div class="mylayout-left small">
					<DroneCard
						:class="droneCardCss"
						:model="droneData.model"
						:lot="droneData.lot"
						:firmware="droneData.firmware"
						:drone="droneData.drone"
						:owner="droneData.owner"
						:connect="droneData.connect"/>
					<DroneActionMenu
						v-if="$route.meta.showAction"
						:drone="droneData.drone"
						:drone-model="droneData.model"
						:firmware="droneData.firmware"
						:connect="droneData.connect"
						:change-request="droneData.change_request"
						:is-enabled-hgr="isEnabledHgr"
						@update="updateDroneActionMenu"/>
				</div>
				<div class="mylayout-right">
					<router-view
						:is-enabled-hgr="isEnabledHgr"
						:drone-data="droneData"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import PageMixin from "@mixins/PageMixin.vue"
import {emptyDrone, fulfillDrone} from "@utils/objectUtil"
import DroneSearchError from '@components/drone/DroneSearchError.vue'
import DroneCard from "@components/drone/DroneCard.vue"
import DroneActionMenu from "@components/drone/DroneActionMenu.vue"
import isString from "lodash/isString"
import DroneSearchPanel from "@/src/components/drone/DroneSearchPanel.vue"
import DroneTable from "@/src/components/drone/DroneTable.vue"
export default {
	components : {
		DroneSearchError,
		DroneActionMenu,
		DroneCard,
		DroneSearchPanel,
		DroneTable
	},
	mixins : [PageMixin] ,
	page() {
		const pageTitle = this.$notEmpty(this.currentSerialNoValue) ? this.$t('page.title',{name : this.currentSerialNoValue}) : this.$t('page.title.search')
		return {
			title : pageTitle
		}
	} ,
	data() {
		return {
			currentSerialNoValue : undefined,
			searchSerialNoValue : undefined,

			searchError : undefined ,
			droneData : emptyDrone() ,
			isEnabledHgr : false,
			searchPanelKey: 0,
			isShowDroneTable: false,
			droneDataList: [],
			pagination: {
				pageSize: 20 ,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			sort: {
				field: 'serial_no',
				order: 'ascend',
			},
			loading: false
		}
	} ,
	computed : {
		paramSerialNoValue() {
			if (this.$notEmpty(this.$route.params.serial_no) && isString(this.$route.params.serial_no)) {
				return this.$route.params.serial_no.trim().toUpperCase()
			} else {
				return undefined
			}
		} ,
		isValid() {
			return this.droneData.drone && this.droneData.drone.id > 0
		} ,
		droneCardCss() {
			return this.$route.meta.showAction ? '' : 'sticky'
		}
	} ,
	watch : {
		'$route.params' : {
			handler(newVal, oldVal) {
				if(this.$notEmpty(newVal.serial_no)) {
					// force update drone search panel when new url include serial no
					this.searchPanelKey++;
					this.isShowDroneTable = false;
				}
				else { 
					this.searchError = undefined;
				}
				if (this.currentSerialNoValue != this.paramSerialNoValue) {
					this.searchSerialNoValue = this.paramSerialNoValue
					this.currentSerialNoValue = undefined
					this.fetchData()
				}
			} ,
			deep : true,
		}
	},
	mounted() {
		this.searchSerialNoValue = this.paramSerialNoValue
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			if (!this.$notEmpty(this.paramSerialNoValue)) {
				this.clearBreadcrumbParams(['droneSerialNo'])
				this.searchError = undefined
				this.droneData = emptyDrone()
			}

			if (!this.$notEmpty(this.searchSerialNoValue)) {
				return
			}
			this.searchError = undefined
			this.showPageLoading(true)
			axios.get("/api/drones/view-by-serial-no/"+this.searchSerialNoValue).then((response) => {
				this.droneData = response.data.data
				this.isEnabledHgr = response.data.data.is_enabled_hgr

				this.currentSerialNoValue = this.droneData.drone.serial_no
				this.searchSerialNoValue = this.droneData.drone.serial_no
				this.addBreadcrumbParams({
					'droneSerialNo' : this.currentSerialNoValue ,
				});
			}).catch((error) => {
				this.searchError = error
				this.droneData = emptyDrone()
				this.addBreadcrumbParams({
					'droneSerialNo' : false ,
				})
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		updateDroneActionMenu(data) {
			this.droneData.drone = {...data.drone}
			this.droneData.owner = {...data.droneOwner}
			this.droneData.change_request = [...data.changeRequest]
		},
		callSearchDrone(toPage = null, keyword, searchType) {
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : toPage,
				sort_field : this.sort.field ,
				sort_order : this.sort.order ,
			}
			const filter = {
				keyword : keyword,
				search_type: searchType,
			}
			this.loading = true;
			axios.get("/api/drones/search",{params : { search, filter }}).then((response)=>{
				const page = response.data.data.pagination;
				const dataBucket = response.data.data.bucket;

				this.droneDataList = response.data.data.drones.map((drone)=>{
					return fulfillDrone(drone, dataBucket)
				})
				this.isShowDroneTable = true;

				this.pagination.current = toPage;
				this.pagination.total = page.total;
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		},
		handleNativeSearch (searchFilter) {
			if (this.$route.name !== 'drone/search'){
				// reset url slug when call search
				this.$open({name : 'drone' });
			}
			this.pagination = {
				pageSize: 20 ,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			};
			this.searchError = undefined;
			this.callSearchDrone(null, searchFilter.keyword, searchFilter.search_type);
		},
		handleTableChangePage(pagination, filters, sorter) {
			const searchFilter = this.$refs.searchFilterRef.getSearchFilter();
			this.sort.field = sorter.columnKey;
			this.sort.order = sorter.order;
			this.callSearchDrone(pagination.current, searchFilter.keyword, searchFilter.search_type);
		}
	}
}
</script>

<style lang="less" scoped>
.input-pane {
	padding : 12px 16px;
	border : 1px solid @white;
	border-radius: @border-radius-base;
	box-shadow : @shadow-1-right;
	background : @white;
	margin-bottom : 16px;
	> label {
		line-height: 32px;
		vertical-align: middle;
		margin-right : 2px;
		color : @info-color;
	}
}
</style>
