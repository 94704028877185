<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_update_modal.title : "ปรับค่า Drone"

drone.field.latest_flight_time_sec.help : "ค่า Flight Time ของ Drone ปัจจุบัน"
drone.field.firmware.help : "ค่า Firmware ปัจจุบัน"
update.field.updated_date : "ข้อมูลวันที่"
update.field.updated_date.help : "วันที่ได้ข้อมูลนี้มา"
update.field.updated_time : "เวลา"
update.field.updated_time.help : "เวลาโดยประมาณ"

</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="$t('drone_update_modal.title')"
		:ok-text="$t('common.save')"
		:width="400"
		:confirm-loading="loading"
		:cancel-button-props="{ props: { disabled: loading } }"
		:mask-closable="!loading"
		@ok="handleOk"
		@cancel="handleCancel">
		<a-form  :form="formObj">
			<a-form-item :label="$t('drone.field.firmware')"
				:extra="$t('drone.field.firmware.help')">

				<FirmwareSelect
						v-decorator="[
						'firmware_id' ,
						{
							rules: [
								{ required: true,message: $tt('validate.required','drone.field.firmware') },
							],
						},
					]"
					:firmware-set-id="droneModel.firmware_set_id"/>
			</a-form-item>

			<a-form-item :label="$t('drone.field.latest_flight_time_sec')"
				:extra="$t('drone.field.latest_flight_time_sec.help')">
				<FlightTimeInput
					v-decorator="[
						'latest_flight_time_sec' ,
						{
							rules: [
							{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },
						] },
					]"/>
			</a-form-item>
			<hr />
			<a-form-item :label="$t('update.field.updated_date')"
				:help="$t('update.field.updated_date.help')">
				<MyDatePicker
					v-decorator="['updated_date',
						{
							rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','update.field.updated_date') },
							] ,
						}]"
					format="DD MMMM YYYY"/>
			</a-form-item>
			<a-form-item :label="$t('update.field.updated_time')"
				:help="$t('update.field.updated_time.help')">
				<a-time-picker
					v-decorator="['updated_time',
						{
							rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','update.field.updated_time') },
							] ,
						}]"
					input-read-only
					format="HH:mm"
					value-format="HH:mm"/>
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import {TimePicker} from "ant-design-vue"
import { updateFieldsValue } from '@utils/formUtil'
import FlightTimeInput from "@components/input/FlightTimeInput.vue"
import FirmwareSelect from "@components/firmware/FirmwareSelect.vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
export default {
	components : {
		MyDatePicker , FlightTimeInput ,
		"a-time-picker" : TimePicker,
		FirmwareSelect ,
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		loading : {
			type : Boolean,
			default : false
		}
	} ,
	data() {
		return {
			visible : false,
			drone : {} ,
			droneModel : {},
		}
	} ,
	methods : {
		openModal(droneModel,drone) {
			this.droneModel = droneModel
			this.drone = drone
			this.visible = true
			const currentTime = this.$dayjs()
			const updatedDate = currentTime.format("YYYY-MM-DD")
			const updatedTime = currentTime.format("HH:mm")
			this.$nextTick(() => {
				this.formObj.resetFields()
				updateFieldsValue(this.formObj,{
					firmware_id : this.drone.firmware_id ,
					latest_flight_time_sec : this.drone.latest_flight_time_sec,
					updated_date : updatedDate,
					updated_time : updatedTime ,
				})
			})
		} ,
		closeModal() {
			this.visible = false
			this.drone = {}
			this.droneModel = {}
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		} ,
		handleOk() {
			this.formObj.validateFields((err,values) => {
				if (!err) {

					this.$emit('submit',{
						formData : {
							...values ,
							'updated_datetime' : values.updated_date+" "+values.updated_time+":00"
						} ,
						id : this.drone.id ,
					})
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		handleCancel() {
			if (this.loading)
				return;
			this.closeModal()
		} ,
	}
}
</script>
